
import 'bootstrap'
import './waypoints'
import './meanmenu'
import 'swiper/swiper-bundle'
import './owl-carousel'
import './owl-carousel-filter'
import './magnific-popup'
import './parallax'
import './smooth-scroll'
import 'jquery-datetimepicker'
//import './nice-select'
import './odometer.min'
import './ajax-form'
import './main'

