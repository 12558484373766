// load block loader
import '../features/loader';

// Import javascript
import '../assets/js/all'

// Ignore the warnings. It's an alias to the domain set by webpack.
import 'domainScript' // import domain script

// Import (s)css
import './style.scss'